import styled, { css } from 'styled-components';

export const Wrapper = styled.span`
	/* https://css-tricks.com/how-to-create-neon-text-with-css/ */
	font-family: var(--headingFont);
	text-transform: uppercase;
	color: var(--white);
	width: fit-content;
	text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
		0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;

	${props =>
		props.color === 'flicker' &&
		css`
			animation: flicker 1.5s infinite alternate;

			/* Flickering animation */
			@keyframes flicker {
				0%,
				18%,
				22%,
				25%,
				53%,
				57%,
				100% {
					text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff,
						0 0 40px #0fa, 0 0 80px #0fa, 0 0 90px #0fa,
						0 0 100px #0fa, 0 0 150px #0fa;
				}

				20%,
				24%,
				55% {
					text-shadow: none;
				}
			}
		`}

	${props =>
		props.color === 'red' &&
		css`
			animation: pulsateRed 0.11s ease-in-out infinite alternate;
			@keyframes pulsateRed {
				100% {
					text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff,
						0 0 40px #f09, 0 0 80px #f09, 0 0 90px #f09,
						0 0 100px #f09, 0 0 150px #f09;
				}

				0% {
					text-shadow: 0 0 4px #fff, 0 0 10px #fff, 0 0 18px #fff,
						0 0 38px #f09, 0 0 73px #f09, 0 0 80px #f09,
						0 0 94px #f09, 0 0 140px #f09;
				}
			}
		`}

	${props =>
		props.color === 'blue' &&
		css`
			animation: pulsateBlue 0.11s ease-in-out infinite alternate;
			@keyframes pulsateBlue {
				100% {
					text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff,
						0 0 40px #5271ff, 0 0 80px #5271ff, 0 0 90px #5271ff,
						0 0 100px #5271ff, 0 0 150px #5271ff;
				}

				0% {
					text-shadow: 0 0 4px #fff, 0 0 10px #fff, 0 0 18px #fff,
						0 0 38px #5271ff, 0 0 73px #5271ff, 0 0 80px #5271ff,
						0 0 94px #5271ff, 0 0 140px #5271ff;
				}
			}
		`}

	${props =>
		props.color === 'purple' &&
		css`
			/* animation: pulsatePurple 1.5s infinite alternate; */
			border: 0.2rem solid #fff;
			border-radius: 1rem;
			padding: 0.4em;
			box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #bc13fe,
				0 0 0.8rem #bc13fe, 0 0 2.8rem #bc13fe, inset 0 0 1.3rem #bc13fe;
			text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #fff,
				0 0 10px #bc13fe, 0 0 45px #bc13fe, 0 0 55px #bc13fe,
				0 0 70px #bc13fe, 0 0 80px #bc13fe;

			@keyframes pulsatePurple {
				100% {
					text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff,
						0 0 40px #bc13fe, 0 0 80px #bc13fe, 0 0 90px #bc13fe,
						0 0 100px #bc13fe, 0 0 150px #bc13fe;
				}

				0% {
					text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #fff,
						0 0 10px #bc13fe, 0 0 45px #bc13fe, 0 0 55px #bc13fe,
						0 0 70px #bc13fe, 0 0 80px #bc13fe;
				}
			}
		`}
`;
