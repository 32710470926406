import React from 'react';
import { Header, Footer } from '../../components';
import 'normalize.css';
import '../../styles/global.css';

export default function Layout({ children }) {
	return (
		<div className="layout">
			<Header />
			<main>{children}</main>
			<Footer />
		</div>
	);
}
