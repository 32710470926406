import React from 'react';
import { Banner } from '../Banner';
import { Body, Wrapper, Facts, Content } from './Article.styled';
import { Back } from '../Back';

export default function Post({ title, date, featuredImg, children }) {
	return (
		<Wrapper>
			<Back />
			<Banner image={featuredImg} title={title} />
			<Content className="content">
				<Facts>
					<time>{date}</time>
				</Facts>
				<Body>{children}</Body>
			</Content>
		</Wrapper>
	);
}
