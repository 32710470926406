import React from 'react';
import { Nav } from '../Nav';
import { Logo } from '../Logo';
import { Wrapper } from './Header.styled';

export default function Header() {
	return (
		<Wrapper>
			<Logo />
			<Nav />
		</Wrapper>
	);
}
