import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.button`
	font-size: 2rem;
	position: fixed;
	top: unset;
	right: var(--gap);
	bottom: var(--gap);
	left: unset;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4.5rem;
	height: auto;
	z-index: 2;

	${media.large`
		top: calc(1rem + var(--headerHeight));
		right: unset;
		bottom: unset;
		left: 1rem;

		&.variant {
			background: none;
			border: none;
		}
	`}
`;
