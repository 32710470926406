import styled from 'styled-components';

export const Links = styled.nav`
	transition: left 0.2s ease-out;
	max-width: 1440px;
	padding: 0;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;

	a {
		display: block;

		@media (min-width: 768px) {
			padding: 0%;
		}
	}
`;

export const List = styled.ul`
	list-style: none;
	padding-left: 0;
	margin: 0;
	background: var(--headerBackground);
	padding: 2rem 0;
	display: flex;
	gap: 1rem;

	@media (min-width: 768px) {
		padding: 0;
		background: none;
	}
`;

export const Item = styled.li`
	line-height: 1.2;

	&.active {
		border-bottom: var(--line);
	}
`;

export const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.65);
	position: fixed;
	top: var(--headerHeight);
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
`;
