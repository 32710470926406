import React from 'react';
import { navigate } from 'gatsby';
import { Wrapper } from './Back.styled';

// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#navigate-to-the-previous-page

export default function Back() {
	return (
		<Wrapper
			aria-label="Go Back"
			className="button variant"
			onClick={() => {
				navigate(-1);
			}}
		>
			&larr;
		</Wrapper>
	);
}
